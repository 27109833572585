<template>
  <div class="page flex-col">
    <div class="group_1 flex-col justify-between">
      <div class="section_2 flex-col">
        <span class="text_1">蓝数GE</span>
        <div class="box_2 flex-col"></div>
        <div class="text-group_2 flex-col justify-between">
          <div class="text-wrapper_1">
            <span class="text_2">领先的</span>
            <span class="paragraph_1">
              <br />
              智慧政务解决方案
            </span>
          </div>
          <span class="text_3">降低政务成本&nbsp;/&nbsp;提升办件效率&nbsp;/&nbsp;优化过程体验</span>
        </div>
        <button class="button_1 flex-col" @click="onClick_1"><span class="text_4">获取方案</span></button>
        
          <div v-show="show">
            <div class="box_3 flex-col"></div>
            <div class="box_4 flex-col">
              <div class="section_1 flex-col"></div>
              <div class="text-wrapper_2">
                <span class="text_5">方案顾问</span>
                <span class="text_6">&nbsp;</span>
                <span class="text_7">(长按保存到相册)</span>
              </div>
            </div>
          </div>
        
        
        <div class="box_5 flex-col"></div>
      </div>
      <div class="text-wrapper_3 flex-col">
        <span class="text_8">
          Copyright&nbsp;2022&nbsp;Lanshusoft.com&nbsp;版权所有&nbsp;&nbsp;
          <a href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2021010349号</a>
          &nbsp;&nbsp;重庆蓝数软件有限公司
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show:false,
      constants: {}
    };
  },
  created () {
    document.title = '蓝数GE'
  },
  mounted () {
    if (this._isMobile()) {
      console.log("手机端");
      // this.$router.push('/lanhu_huabanbeifen');
    } else {
      console.log("pc端");
      this.$router.push('/index');
    }
  },
  methods: {
    onClick_1() {
      this.show = !this.show
    },
    // 判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
