import Vue from 'vue'
import VueRouter from 'vue-router'
import phone from '../views/phone/index.vue'
import pc from '../views/pc/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/index"
  },
  {
    path: '/phone',
    name: 'phone',
    component: phone
  },
  {
    path: '/index',
    name: 'index',
    component: pc
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
